/*  imported from sendResetPswMailForm.less  */

#meepshop { .store-login__sendResetPswMailForm__buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  button {
    border-radius: var(--button-border-radius);
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";